export const environment = {
  production: true,
  nau_url: 'https://match.naumatch.com.br/',
  mobile_url_nau: 'https://match.naumatch.com.br/mobile/',
  web_url_nau: 'https://match.naumatch.com.br/',
  url_api: 'https://new-match-api.naumatch.com.br/',
  auth_url_api: 'https://new-match-api.naumatch.com.br/auth/',

  googleUrl: `https://new-match-api.naumatch.com.br/google/login`
};
