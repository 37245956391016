<header id="header" #header [ngClass]="{
    fixed: !!backgroundMaskConfig || !!isFixed,
    sticky: !backgroundMaskConfig && !isFixed,
    inverse: !!isInverse
  }" [ngStyle]="
    (backgroundMaskConfig &&
    !isBackgroundColored) && {
      'background-image': 'url(' + backgroundMask + ')',
      'background-position': backgroundMaskConfig.position,
      height: backgroundMaskConfig.size
    }
  ">
  <nav class="navbar navbar-expand-lg navbar-dark mb-5 mx-lg-3 mx-0">
    <a class="navbar-brand" [href]="isYoungPage || (isProfilePage && isYoung)  ? 'jovem' : ''">
      <img class="logo"
        [src]="isBackgroundColored || isFixed ? '/assets/img/brand/logo-caption-white.png' : '/assets/img/brand/logo-caption-black.png'"
        alt="Nau-Match logo" height="95" />
    </a>
    <div class="collapse navbar-collapse justify-content-center mb-lg-0 mb-md-4 mb-4">
      <ul class="navbar-nav">
        <li class="nav-item active mt-lg-0 mt-4" *ngFor="let link of links">
          <a class="body-xl nav-link nav-link-padding" target="_self" [href]="link.url"
            [ngStyle]="checkUrlLink(link.url)">
            {{ link.name }}
          </a>
        </li>
      </ul>
    </div>

    <div class="float-left px-xl-4 px-lg-3 px-4" *ngIf="!isAuthenticated && !!isCompany">
      <div class="row align-items-center">
        <button class='col-auto btn btn-primary h-100'
          (click)="scrollTo('company-register')">
          Contrate Agora
        </button>
        <div class='col-auto px-3'>
          <button (click)="redirectTo('login')" class="btn btn-lg h-100" [ngClass]="{
              'btn-outline-light':
                !isInverse || isBackgroundColored || backgroundMaskConfig,
              'btn-outline-primary': !!isInverse && !isBackgroundColored
            }">
            Login
          </button>
        </div>
        <button class="navbar-toggler col-auto" (click)="isSideMenuOpen = !isSideMenuOpen" type="button"
          data-toggle="collapse">
          <div class="menu" [ngClass]="{ 'is-collapsed': !isCollapsed }">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="float-left px-xl-4 px-lg-3 px-4" *ngIf="!isCompany && !isAuthenticated">
      <div class="row align-items-center">
        <button (click)="onShowLoginModal('register')"
          class='col-auto btn btn-lg btn-primary h-100 mobile-none-button'>
          Criar conta
        </button>
        <div class='col-auto px-3'>
          <button (click)="onShowLoginModal()" class="btn btn-lg h-100" [ngClass]="{
              'btn-outline-light':
                !isInverse || isBackgroundColored || backgroundMaskConfig,
              'btn-outline-primary': !!isInverse && !isBackgroundColored
            }">
            Login
          </button>
        </div>
        <button class="navbar-toggler col-auto" (click)="isSideMenuOpen = !isSideMenuOpen" type="button"
          data-toggle="collapse">
          <div class="menu" [ngClass]="{ 'is-collapsed': !isCollapsed }">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="float-left px-3" *ngIf="!!isAuthenticated">
      <div dropdown placement="bottom right" container="button">
        <button id="avatar-dropdown" dropdownToggle type="button" (click)="onUpsideEvent(!this.isUpside)"
          class='body-lg btn-lg btn btn-primary dropdown-block mobile-none-button' aria-controls="avatar-dropdown">
          <div class="row w-100 mx-0 align-items-center">
            <div class="photo rounded-circle mr-3">
              <div class='avatar'>
                <img *ngIf="!user.photo" class="rounded-circle" src="/assets/img/perfil/users.svg" />
                <img class="rounded-circle" width="100%" *ngIf="user.photo" [src]="user.photo" />
              </div>
            </div>
            <span class='text-line-overflow text-light' style="max-width: 200px">
              {{user?.first_name}}
            </span>
            <span class="material-icons ml-2">
              expand_more
            </span>
          </div>
        </button>


        <ul id="avatar-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
          aria-labelledby="avatar-dropdown">
          <li role="menuitem">
            <a [href]="isYoung ? 'jovem/dashboard' : 'dashboard'" class="dropdown-item">Sua conta</a>
          </li>
          <li role="menuitem" (click)="logout()">
            <a class="dropdown-item">Sair</a>
          </li>
        </ul>
      </div>

      <button class="navbar-toggler col-auto" (click)="isSideMenuOpen = !isSideMenuOpen" type="button"
        data-toggle="collapse">
        <div class="menu" [ngClass]="{ 'is-collapsed': !isCollapsed }">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
      </button>
    </div>
  </nav>
</header>

<app-side-menu (onClose)="isSideMenuOpen = !isSideMenuOpen" [links]="links" [isYoungPage]="isYoungPage" [user]="user"
  [isOpen]="isSideMenuOpen">
</app-side-menu>
<app-login-modal [initialStep]="initialStep" [isOpen]="isLoginOpen" (onClose)="closeModal()"></app-login-modal>
